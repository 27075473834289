let config = {
  apiBaseUrl: process.env.apiBaseUrl || "https://testtdms2017.tdmsportal.com", //"http://localhost/tdms2017", //,"http://localhost/tdms2017", //"https://tdms2017.tdmsportal.com" //
  // apiBaseUrl: "http://2f08-102-67-1-37.ngrok.io", //"http://bf079baf4175.ngrok.io",
  // apiBaseUrl: process.env.apiBaseUrl || "http://testservice.tdmsportal.com",
  categoryADealers: [3],
  categoryBDealers: [1, 2, 4, 5, 6, 7, 8, 9],
  navBaseurl:
    "http://52.137.42.158:7048/DynamicsNAV80/OData/Company('TOYOTA%20NIGERIA%20LIMITED')",
  navUsername: "tnladmin",
  navPassword: "Tnl@992lekki",
  navAltUsername: "Webuser",
  navAltPassword: "Simple@123",
  navAltURL:
    "http://tnltesterp.uksouth.cloudapp.azure.com:14048/BC140/OData/Company('TOYOTA NIGERIA LIMITED')/Dealer_Credit_Limit?$format=json"
};
module.exports = config;
